import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/default-page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "contactez-moi-",
      "style": {
        "position": "relative"
      }
    }}>{`Contactez moi !`}<a parentName="h1" {...{
        "href": "#contactez-moi-",
        "aria-label": "contactez moi  permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h1>
    <p>{`Merci à vous d'avoir pris le temps de lire mon contenu, j'espère qu'il vous sera utile ! J'adore partager ma passion, c’est toujours un plaisir d’échanger !`}</p>
    <p>{`Si vous avez des commentaires sur le site ou sur un article, n'hésitez pas à `}<a parentName="p" {...{
        "href": "https://twitter.com/rmaximedev",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`me tweeter, m'envoyer un dm`}</a>{` ou ouvrir `}<a parentName="p" {...{
        "href": "https://github.com/zyhou/maximerichard.dev/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`une issue sur Github`}</a>{` !`}</p>
    <p>{`Vous souhaitez que je prenne la parole lors de votre événement, m'inviter sur votre podcast ou que j'écrive un article sur votre blog ?`}</p>
    <p>{`Envoyez-moi un message ! Je vous répondrai le plus rapidement possible.`}</p>
    <div className="text-center font-bold">
    <a rel="nofollow noopener noreferrer" target="_blank" href="mailto:me@maximerichard.dev">
        me@maximerichard.dev
    </a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      